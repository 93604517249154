.container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 0px 120px;
	cursor: default;
	overflow: hidden;
}

.imgWrapper {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.img {
	width: auto;
	height: 100%;
}

.contextWrapper {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.context {
}

.contextRow {
	font-family: PingFangSC-Regular;
	font-size: 40px;
	color: #333333;
	text-align: center;
}

.contextRowTip {
	font-size: 28px;
}

@media (max-width: 768px) {
	.container {
		padding: 0px;
	}
	
	.contextWrapper {
		width: 100%;
	}

	.contextRow {
		font-size: 18px;
	}

	.contextRowTip {
		font-size: 18px;
	}
}
