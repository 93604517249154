.Loading_container__rCQnd {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.Loading_loading__IJ6oT {
	margin: auto;
}

.LoadingAni_loader__vicU6 {
	/*width: calc(1em * 7 + 0.15em * 6);*/
	/*height: 1.5em;*/
	font-size: 20px;
	display: flex;
	justify-content: space-between;
}

.LoadingAni_loader__vicU6 span {
	/*width: 1em;*/
	background-color: deepskyblue;
	border-radius: 0.1em;
	-webkit-transform: skewX(-25deg) scale(0.1);
	        transform: skewX(-25deg) scale(0.1);
	-webkit-animation: LoadingAni_animate__2-lf0 0.6s infinite alternate;
	        animation: LoadingAni_animate__2-lf0 0.6s infinite alternate;
	-webkit-filter: opacity(0);
	        filter: opacity(0);
	-webkit-animation-delay: calc((var(--n) - 1) * 0.05s);
	        animation-delay: calc((var(--n) - 1) * 0.05s);
}

@-webkit-keyframes LoadingAni_animate__2-lf0 {
	to {
		-webkit-filter: opacity(1);
		        filter: opacity(1);
		-webkit-transform: skewX(-25deg) scale(1);
		        transform: skewX(-25deg) scale(1);
	}
}

@keyframes LoadingAni_animate__2-lf0 {
	to {
		-webkit-filter: opacity(1);
		        filter: opacity(1);
		-webkit-transform: skewX(-25deg) scale(1);
		        transform: skewX(-25deg) scale(1);
	}
}

.LoadingAni_loader__vicU6 span:nth-child(1) {
	--n: 1;
}

.LoadingAni_loader__vicU6 span:nth-child(2) {
	--n: 2;
}

.LoadingAni_loader__vicU6 span:nth-child(3) {
	--n: 3;
}

.LoadingAni_loader__vicU6 span:nth-child(4) {
	--n: 4;
}

.LoadingAni_loader__vicU6 span:nth-child(5) {
	--n: 5;
}

.LoadingAni_loader__vicU6 span:nth-child(6) {
	--n: 6;
}

.LoadingAni_loader__vicU6 span:nth-child(7) {
	--n: 7;
}

.Header_menuWrapper__1uF_U {
	line-height: 63px;
	font-size: 18px;
	border: none;
}

.Header_header__1mDYr {
	width: 100%;
	height: 78px;
	-webkit-user-select: none;
	        user-select: none;
	background-color: transparent;
	padding: 0px 120px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* box-shadow: 0 2px 23px 0 rgba(72, 34, 235, 0.1); */
	flex-shrink: 0;
}

@media (max-width: 768px) {
	.Header_header__1mDYr {
		padding: 0px 10px;
	}
}

.Header_header_transparent__39Z6C {
	background-color: transparent;
	box-shadow: none;
}

.Header_headerLogoContainer__36kdd {
	display: flex;
}

.Header_headerLogo__3w2Nd {
	display: flex;
	margin-right: 20px;
}

.Header_logo__1FSKY {
	width: 45px;
	height: 53px;
	margin: auto;
}

.Header_logo__1FSKY svg {
	height: 100%;
	width: 100%;
}

.Header_header__1mDYr .Header_logo__1FSKY svg {
	color: #000000;
}

.Header_header_transparent__39Z6C .Header_logo__1FSKY svg {
	color: #ffffff;
}

.Header_headerLogoText__3WDgD {
	font-family: PingFangSC-Semibold;
	font-size: 34px;
	letter-spacing: 0;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.Header_header__1mDYr .Header_headerLogoText__3WDgD {
	color: #000000;
}

.Header_header_transparent__39Z6C .Header_headerLogoText__3WDgD {
	color: #ffffff;
}

.Header_headerMenuWrapper__2P-NI {
	display: flex;
}

.Header_menu__rkByz {
}

.Header_menu__rkByz > li {
	height: 100%;
}

.Header_menu__rkByz .ant-menu-item {
	border: none;
}

.Header_menu__rkByz .ant-menu-item div {
	font-family: PingFangSC-Regular;
	font-size: 24px;
	color: #999999;
	letter-spacing: 0;
	text-align: center;
	margin: 27px 0px;
	line-height: 1;
}

.Header_header__1mDYr .Header_menu__rkByz .ant-menu-item div {
	color: #000000;
}

.Header_header_transparent__39Z6C .Header_menu__rkByz .ant-menu-item div {
	color: #999999;
}

.Header_header__1mDYr .Header_menu__rkByz .ant-menu-item.ant-menu-item-selected div {
	color: #2659ff;
}

.Header_header_transparent__39Z6C .Header_menu__rkByz .ant-menu-item.ant-menu-item-selected div {
	color: #ffffff;
}

.Header_langWrapper__27jhX {
	display: flex;
	cursor: not-allowed;
}

.Header_langText__Nu8vj {
	font-family: PingFangSC-Regular;
	font-size: 24px;
	letter-spacing: 0;
	text-align: center;
	margin: 27px 0px 27px 15px;
	line-height: 1;
}

.Header_header__1mDYr .Header_langText__Nu8vj {
	color: #000000;
}

.Header_header_transparent__39Z6C .Header_langText__Nu8vj {
	color: #999999;
}

.Header_dropdownIconWrapper__1-7dV {
	height: 100%;
	display: flex;
}

.Header_dropdownIcon__5zbdD {
	font-size: 24px;
	margin: auto 20px;
}

.Header_header__1mDYr .Header_dropdownIcon__5zbdD {
	color: #000000;
}

.Header_header_transparent__39Z6C .Header_dropdownIcon__5zbdD {
	color: #999999;
}

.Header_dropdownOverlay__3AvF4 {
	width: 100%;
}

.Header_dropdown__1yUzS {
}

.Header_dropdown__1yUzS .Header_menu__rkByz .ant-menu-item div {
	font-size: 14px;
	margin: 13px 0px;
}

.Header_dropdown__1yUzS .ant-drawer-body {
	padding: 20px;
}

.Footer_footer__1PgtF {
	-webkit-user-select: none;
	        user-select: none;
	background: #081855;
	padding: 28px 0px;
}

.Footer_footerDes__6RcVc {
	display: flex;
	justify-content: space-around;
	font-family: PingFangSC-Medium;
	font-size: 14px;
	color: #bfbfbf;
	letter-spacing: 0;
	text-align: center;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'PingFang SC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}

.Home_mainContainer__zCHa4 {
  width: 100%;
  overflow: hidden auto;
  background-color: transparent;
  margin: 0 auto;
}

.Home_mainContainerWithBg__3Z8r1 {
}

.Home_mainContainerWithBg2__2Yhy1 {
}

.Home_container__pl5V6 {
  width: 100%;
  display: flex;
  margin: 0px;
  min-height: 100vh;
  transition: all 0.2s linear, ease;
  flex-direction: column;
}

.Home_mainContainerWithBg__3Z8r1 .Home_container__pl5V6 {
  background: #0000 url(/static/media/banner.b78e65e2.png) no-repeat scroll center -2px;
  background-size: 100% 770px;
}

.Home_mainContainerWithBg2__2Yhy1 .Home_container__pl5V6 {
  background: linear-gradient(
    to bottom,
    rgba(235, 255, 253, 1) 2%,
    rgba(235, 241, 253, 1) 29%,
    rgba(183, 207, 253, 1) 100%
  );
}

.Home_headerRow__20Ehf {
}

.Home_content__3j4Cg {
  display: flex;
  margin: 0px;
  flex: 1 1;
}

.Home_contentBody__31GVx {
  width: 100%;
  text-align: center;
  flex: auto;
  background: #0000;
}

.Home_bodyRow__3utRY {
  height: 100%;
  background: #0000;
}

.ant-notification-bottomLeft.ant-notification {
  width: auto;
}

.Home_notification__3gv7D {
}

.MainPage_container__1xKi6 {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.MainPage_chapter__2Jg85 {
}

/*Banner*/
.MainPage_bannerContainer__1VZt6 {
	height: 692px;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	cursor: default;
}

.MainPage_bannerLabelWrapper__2QYhh {
	display: flex;
	flex-direction: column;
}

.MainPage_bannerLabel__1Podw {
	font-family: PingFangSC-Regular;
	font-size: 55px;
	color: #ffffff;
	letter-spacing: 5px;
	text-align: center;
	line-height: 77px;
}

.MainPage_bannerTranslate__1Rz85 {
	font-family: PingFangSC-Regular;
	font-size: 35px;
	color: #ffffff;
	letter-spacing: 2px;
	text-align: center;
	line-height: 49px;
}

/*Info1*/
.MainPage_info1Container__2cCra {
	width: 100%;
	height: 670px;
	position: relative;
	display: flex;
	flex-direction: row;
	padding: 0px 120px;
	cursor: default;
}

.MainPage_info1Container__2cCra .MainPage_infoBgImg__1PzpI {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.MainPage_info1Container__2cCra .MainPage_infoImgWrapper__3rrEZ {
	width: 50%;
	display: flex;
	justify-content: center;
}

.MainPage_info1Container__2cCra .MainPage_infoImg__1nvzb {
	width: 75%;
	max-width: 550px;
	height: auto;
	margin: auto 0px;
}

.MainPage_info1Container__2cCra .MainPage_infoContext__2uMe1 {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.MainPage_info1Container__2cCra .MainPage_infoContentWrapper__1jSyo {
	display: flex;
	justify-content: center;
}

.MainPage_info1Container__2cCra .MainPage_infoContent__24SBD {
	font-family: PingFangSC-Regular;
	font-size: 24px;
	color: #333333;
	letter-spacing: 0;
	line-height: 40px;
}

/*Info2*/
.MainPage_info2Container__1Xiof {
	width: 100%;
	height: 670px;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 0px 120px;
	cursor: default;
}

.MainPage_info2Container__1Xiof .MainPage_infoBgImg__1PzpI {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
	padding: 0px 120px;
}

.MainPage_info2Container__1Xiof .MainPage_infoImgWrapper__3rrEZ {
	width: 50%;
	display: flex;
	justify-content: center;
}

.MainPage_info2Container__1Xiof .MainPage_infoImg__1nvzb {
	width: 75%;
	max-width: 550px;
	height: auto;
	margin: auto 0px;
}

.MainPage_info2Container__1Xiof .MainPage_infoContext__2uMe1 {
	width: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.MainPage_info2Container__1Xiof .MainPage_infoContentWrapper__1jSyo {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.MainPage_info2Container__1Xiof .MainPage_infoContent__24SBD {
	font-family: PingFangSC-Regular;
	font-size: 24px;
	color: #333333;
	letter-spacing: 0;
	line-height: 40px;
	font-weight: bold;
}

.MainPage_info2Container__1Xiof .MainPage_infoTips__3kLs5 {
	font-family: PingFangSC-Medium;
	font-size: 20px;
	color: #2659ff;
	letter-spacing: 0;
	margin-top: 20px;
	font-weight: bold;
}

/*Title*/
.MainPage_titleContainer__1YpsA {
	width: 100%;
	display: flex;
	justify-content: center;
	margin: 80px 0px 50px 0px;
	cursor: default;
}

.MainPage_titleWrapper__3vA4n {
	display: flex;
}

.MainPage_titleDecorate__3LEUm {
	display: flex;
}

.MainPage_leftRect__11EtW {
	margin: auto 0px;
}

.MainPage_titleContextWrapper__2g17R {
	margin: 0px 24px;
}

.MainPage_titleContext__2uIrx {
	font-family: PingFangSC-Regular;
	font-size: 24px;
	color: #333333;
	letter-spacing: 0;
}

.MainPage_rightRect__38BS5 {
}

/*Card*/
.MainPage_cardContainer__3EQR_ {
	width: 250px;
	height: 330px;
	background: #ffffff;
	box-shadow: -1px 13px 20px 0 #f1f0f4;
	border-radius: 5px;
	cursor: default;
}

.MainPage_cardImgWrapper__5NYo2 {
	display: flex;
	justify-content: center;
	margin: 60px 0px 0px 0px;
}

.MainPage_cardImg__2fXyF {
	width: 80px;
	height: 80px;
}

.MainPage_cardContextWrapper__1DmWh {
	display: flex;
	flex-direction: column;
}

.MainPage_cardLabel__spnBN {
	margin: 30px 0px;
	font-family: PingFangSC-Regular;
	font-size: 18px;
	color: #263238;
	line-height: 24px;
	font-weight: bold;
}

.MainPage_cardLabel_notInfo__3HD9d {
	height: 100%;
	display: flex;
	justify-content: center;
	margin: auto;
	font-family: PingFangSC-Regular;
	font-size: 20px;
	color: #333333;
	text-align: center;
	font-weight: bold;
}

.MainPage_cardContext__3JvED {
	margin: 0px 20px 0px 20px;
	font-family: PingFangSC-Regular;
	font-size: 14px;
	color: #263238;
	line-height: 24px;
	text-align: left;
}

/*Advantages*/
.MainPage_advantagesContainer__3M7Is {
	width: 100%;
	height: 670px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.MainPage_advantagesBgImg__dGpXw {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.MainPage_advantagesCardsWrapper__1mrdr {
	display: flex;
	justify-content: space-between;
	padding: 20px 120px;
	margin: auto 0px;
}

/*Expectations*/
.MainPage_expectationsContainer__4qTv_ {
	width: 100%;
	height: 470px;
	position: relative;
	display: flex;
	flex-direction: column;
}

.MainPage_expectationsBgImg__84kK0 {
	width: 100%;
	height: 100%;
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: -1;
}

.MainPage_expectationsCardsWrapper___ruwi {
	display: flex;
	justify-content: space-between;
	padding: 0px 120px;
	margin: auto 0px;
}

@media (max-width: 768px) {
	.MainPage_bannerLabel__1Podw {
		font-size: 25px;
	}

	.MainPage_bannerTranslate__1Rz85 {
		font-size: 15px;
	}

	.MainPage_info1Container__2cCra,
	.MainPage_info2Container__1Xiof {
		height: auto;
		flex-direction: column;
		margin: 50px 0px;
		padding: 0px 10px;
	}

	.MainPage_info1Container__2cCra .MainPage_infoBgImg__1PzpI {
		padding: 0px;
	}

	.MainPage_info2Container__1Xiof .MainPage_infoBgImg__1PzpI {
		display: none;
	}

	.MainPage_info1Container__2cCra .MainPage_infoContent__24SBD,
	.MainPage_info2Container__1Xiof .MainPage_infoContent__24SBD {
		font-size: 15px;
		margin-top: 20px;
	}

	.MainPage_info1Container__2cCra .MainPage_infoImgWrapper__3rrEZ,
	.MainPage_info1Container__2cCra .MainPage_infoImg__1nvzb,
	.MainPage_info1Container__2cCra .MainPage_infoContext__2uMe1,
	.MainPage_info2Container__1Xiof .MainPage_infoImgWrapper__3rrEZ,
	.MainPage_info2Container__1Xiof .MainPage_infoImg__1nvzb,
	.MainPage_info2Container__1Xiof .MainPage_infoContext__2uMe1 {
		width: 100%;
	}

	.MainPage_titleContainer__1YpsA {
		margin: 30px 0px 10px 0px;
	}

	.MainPage_leftRect__11EtW,
	.MainPage_rightRect__38BS5 {
		width: 100px;
	}
	.MainPage_leftRect__11EtW img,
	.MainPage_rightRect__38BS5 img {
		width: 100%;
	}

	.MainPage_advantagesContainer__3M7Is {
		height: auto;
	}

	.MainPage_advantagesBgImg__dGpXw {
		display: none;
	}

	.MainPage_advantagesCardsWrapper__1mrdr {
		flex-direction: column;
		padding: 0px 20px;
		margin: auto;
	}

	.MainPage_cardContainer__3EQR_ {
		margin: 30px 0px;
	}

	.MainPage_expectationsContainer__4qTv_ {
		height: auto;
	}

	.MainPage_expectationsBgImg__84kK0 {
		display: none;
	}

	.MainPage_expectationsCardsWrapper___ruwi {
		flex-direction: column;
		padding: 0px 20px;
		margin: auto;
	}
}

.Expectations_cardContainer__3ErUf {
	box-shadow: none;
	background-color: #0000;
	border: none;
	width: 33%;
	height: 100%;
}

.Expectations_cardImgWrapper__1S0M5 {
	margin: 0px;
}

.Expectations_cardImg__1efA3 {
}

.Expectations_cardContextWrapper__kUM9_ {
	border-right: 1px solid #dfdfdf;
}

.Expectations_cardContextWrapper__kUM9_:last-child {
	border-right: 1px solid #dfdfdf;
}

.Expectations_cardContextWrapper_notInfo__U1sAN {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.Expectations_cardLabel__2KdkX {
}

.Expectations_cardLabel_notInfo__1qSRH {
	height: auto;
	width: 80px;
	word-break: break-all;
	overflow-wrap: break-word;
}

.Expectations_cardContext__2hQqI {
	text-align: center;
}

@media (max-width: 768px) {
	.Expectations_cardContainer__3ErUf {
		width: 100%;
		height: 200px;
	}

	.Expectations_cardContextWrapper__kUM9_ {
		border-right: none;
	}

	.Expectations_cardContextWrapper__kUM9_:last-child {
		border-right: none;
	}
}

.Waiting_container__1sQG0 {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 0px 120px;
	cursor: default;
	overflow: hidden;
}

.Waiting_imgWrapper__3iSzp {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.Waiting_img__3yzLS {
	width: auto;
	height: 100%;
}

.Waiting_contextWrapper__1HdCr {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.Waiting_context__2ZPwG {
}

.Waiting_contextRow__3fouD {
	font-family: PingFangSC-Regular;
	font-size: 40px;
	color: #333333;
	text-align: center;
}

.Waiting_contextRowTip__3w35p {
	font-size: 28px;
}

@media (max-width: 768px) {
	.Waiting_container__1sQG0 {
		padding: 0px;
	}
	
	.Waiting_contextWrapper__1HdCr {
		width: 100%;
	}

	.Waiting_contextRow__3fouD {
		font-size: 18px;
	}

	.Waiting_contextRowTip__3w35p {
		font-size: 18px;
	}
}

.NoMatch_container__3wFt6 {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: row-reverse;
	padding: 0px 120px;
	cursor: default;
	overflow: hidden;
}

.NoMatch_imgWrapper__2TnIX {
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	z-index: -1;
}

.NoMatch_img__dYXew {
	width: auto;
	height: 100%;
}

.NoMatch_contextWrapper__s-qoI {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.NoMatch_context__33iGK {
}

.NoMatch_contextRow__2LeKI {
	font-family: PingFangSC-Regular;
	font-size: 40px;
	color: #333333;
	text-align: center;
}

.NoMatch_contextRowTip__U65_A {
	font-size: 28px;
}

@media (max-width: 768px) {
	.NoMatch_container__3wFt6 {
		padding: 0px;
	}
	
	.NoMatch_contextWrapper__s-qoI {
		width: 100%;
	}

	.NoMatch_contextRow__2LeKI {
		font-size: 18px;
	}

	.NoMatch_contextRowTip__U65_A {
		font-size: 18px;
	}
}

